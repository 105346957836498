/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
  list-style-type: none;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  /* background-image: url("./assets//images/BG_original.jpg"); */
  background-image: url("./assets//images/rsz_1bg-seeds_resized_reduced.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover


}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li a{
  text-transform: uppercase;
  margin: 0 1em 0 1em;
  /* font-family: Arial, Helvetica, sans-serif !important; */
  color: rgb(202, 201, 201);
  text-decoration: none;
  padding: 1rem;
  cursor: pointer;
  transition: 0.3s;
  /* box-shadow: inset  0 0 0 0 rgb(3, 19, 43); */
  border-radius: 50px;
  
}

#navbar ul li a:hover {
  color: white;
  box-shadow: inset  100px 0 100px 0 rgb(6, 24, 51);;
}

.active {
  color: white;
  /* box-shadow: inset  100px 0 100px 0 rgb(13, 106, 109);; */
}

.App {
  width: 100%;
  height: 100vh
}

.img-container {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem
}

.nav {
  animation-name: descend;
  animation-duration: 0.2s;
  position: "top";
  top: 0;
}

.cta:hover{
  transform: scale(1.05);
}

.footer-icon:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 1024px) {
  .img-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .img-container {
    grid-template-columns: 1fr;
  }

}

@keyframes descend{
  from{ transform: translateY(-40px); }
  to{ transform: translateY(0px); }
}


.logo:hover {
  transform: scale(1.05);
  transition: 0.3s;
}

.frame {
  filter: grayscale(100%);
  transition: 0.5s;
}
.frame:hover {
  filter: grayscale(0%);
}

.buyticket {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 35px;
  min-width: 125px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
}

.buyticket:hover {
  color: black;
  background-color: white;
}

.tracklist ol li {
  color: white;
  padding: 0 0 0.3rem 0rem;
  font-size: 1.5rem;
}